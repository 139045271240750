.mainCont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #000111;
  height: 100%;
  padding-bottom: 2rem;
}
.headCont {
  background: #000111;
  padding: 4rem 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.head {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  gap: 20px;
}
.head h1 {
  font-size: 64px;
  font-weight: 600;
  color: var(--light-purple);
  line-height: 1;
}
.head p {
  font-size: 24px;
  font-weight: 600;
  color: white;
  line-height: 1;
}
.head a {
  font-size: 16px;
  font-weight: 600;
  color: var(--button-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  line-height: 1;
  transition: all 0.3s ease-in-out;
}
.head a:hover {
  opacity: 0.6;
}
.auctionDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  padding-top: 2rem;
  padding-inline: 20px;
}
.farmRowCont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  border-radius: 20px;
  overflow: hidden;
  background-color: white;
  border: 2px solid rgb(255, 7, 7);
  margin-top: 2rem;
}
.farmRowGrid {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  overflow: hidden;
  margin-top: 2rem;
  column-gap: 20px;
  row-gap: 60px;
  padding-bottom: 1rem;
}
.toTop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 24px 0px;
  color: var(--button-bg);
  font-size: 18px;
  line-height: 1;
  border-top: 1px solid rgb(233, 234, 235);
  cursor: pointer;
  user-select: none;
}
.topChange {
  border: 2px solid rgb(233, 234, 235);
  background-color: white;
  border-radius: 0px 0px 24px 24px;
}
