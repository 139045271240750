.farmRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}
.farmRowTop {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  height: 80px;
  place-items: center;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid rgb(233, 234, 235);
}
.farmRowTop1 {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  height: 80px;
  place-items: center;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid rgb(233, 234, 235);
}
.rowImg {
  padding-left: 10px;
  justify-self: flex-start;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}
.absDiv {
  position: relative;
  width: fit-content;
}
.rowImg h1 {
  color: var(--dark-purple);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
}
.rowImg img {
  width: 20px;
  height: 20px;
}
.rowImg .absImg {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 100%;
  height: 100%;
}
.check {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  font-weight: 400;
  height: 24px;
  padding: 0px 4px;
  font-size: 12px;
  color: var(--light-purple);
  background: rgb(250, 249, 250);
  border: 2px solid var(--light-purple);
  justify-self: flex-start;
  margin-left: 20px;
}
.redCheck {
  justify-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  font-weight: 400;
  height: 24px;
  padding: 0px 4px;
  font-size: 12px;
  background-color: rgb(250, 249, 250);
  color: rgb(237, 75, 158);
  background: rgb(250, 249, 250);
  border: 2px solid rgb(237, 75, 158);
  margin-left: 20px;
}
.rowCol,
.rowColDesk {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 9px;
}
.rowCol p,
.rowColDesk p {
  color: var(--purple-color);
  line-height: 1;
  font-size: 12px;
}
.rowCol h5,
.rowColDesk h5 {
  color: var(--dark-purple);
  line-height: 1;
  font-weight: 500;
  font-size: 16px;
}
.farmRowTop svg {
  transition: all 0.6s ease-in-out;
}
.rowCol svg,
.rowColDesk svg {
  margin-left: 5px;
}
.rowCol h5:hover,
.rowColDesk h5:hover {
  opacity: 0.4;
}
.farmRowBottom {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  column-gap: 1rem;
  width: 100%;
  background-color: rgb(246, 246, 246);
  max-height: 0px;
  height: 100%;
  padding: 0px 32px;
  transition: all 0.6s linear;
}
.rotate {
  transform: rotate(180deg);
}
.farmRowOpen {
  padding: 16px 32px;
  max-height: 900px;
}
.rowLinks {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  min-width: 250px;
}
.rowLinks a {
  display: flex;
  color: var(--button-bg);
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  text-decoration: none;
}
.rowLinks a:hover {
  text-decoration: underline;
}
.btnDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
}

.earnedDiv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 16px;
  border: 2px solid rgb(238, 234, 244);
  border-radius: 16px;
  width: 100%;
  gap: 10px;
  flex: 1;
}
.earnedDiv h5 {
  color: var(--purple-color);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
}
.earnedDiv .earnRow {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
.earnRow p {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  color: var(--darkred-color);
}
.earnedDiv button {
  width: 100%;
}
.earnedDiv .buttonRow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: 10px;
}
.harvestDisable {
  position: relative;
  align-items: center;
  border: 1px solid rgb(233, 234, 235);
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: all 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  background-color: #E2290145;
  box-shadow: none;
  color: #E22901;
  cursor: not-allowed;
  width: fit-content !important;
}
.harvestEnable {
  position: relative;
  align-items: center;
  border: 2px solid transparent;
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: all 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  background-color: var(--button-bg);
  box-shadow: none;
  color: white;
  width: fit-content !important;
}
.harvestEnable:hover {
  background-color: transparent;
  color: var(--button-bg);
  border-color: var(--button-bg);
}

.bottomRowCol {
  display: none;
}
.detailsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.detailsDiv h2 {
  font-weight: 500;
  color: var(--button-bg);
  font-size: 16px;
  line-height: 1;
  display: none;
}
@media screen and (max-width: 1024px) {
  .farmRow {
    position: relative;
    isolation: isolate;
  }
  .farmRowTop {
    background-color: #E2290190;
  }
  .farmRowBottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    transform: translateY(-300px);
    z-index: -1;
  }
  .farmRowTop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .farmRowOpen {
    transform: none;
  }
  .redCheck,
  .check {
    display: none;
  }
  .detailsDiv h2 {
    display: inline;
  }
  .detailsDiv svg {
    color: var(--button-bg);
  }
  .rowColDesk {
    display: none;
  }
  .btnDiv {
    order: 1;
  }
  .rowLinks {
    order: 3;
  }
  .bottomRowCol {
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
  .bottomRowCol .rowCol {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .bottomRowCol .rowCol p {
    font-size: 16px;
    color: var(--dark-purple);
  }
}
@media screen and (max-width: 646px) {
  .btnDiv {
    flex-direction: column;
    height: 250px;
  }
  .farmRowTop {
    grid-template-columns: 1fr 1fr auto;
    grid-template-rows: 1fr 1fr;
    padding: 20px 20px;
    height: 150px;
  }
  .rowImg {
    grid-column: 1/3;
    padding: 0px;
  }
  .redCheck,
  .check {
    margin-left: 0px;
    grid-column: 3/4;
    justify-self: flex-end;
    grid-row: 1/2;
    display: flex;
  }
  .detailsDiv {
    grid-column: 3/4;
    grid-row: 2/3;
    justify-self: flex-end;
  }
  .rowCol {
    justify-self: flex-start;
  }
}
.headerDiv{
  display: flex;
  width: fit-content;
}
.headerInfo1{
  text-align: right;
  color: #E22901;
  margin-right: 10px;
}
.headerInfo2{
  text-align: left;
}
.headerInfo{
  width: 60%;
  position: relative;
  display: flex;
  margin: 10px;
}
.harvestRow{
  width: 100%;
  justify-items: center;
}