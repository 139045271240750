.farmBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 370px;
  background-color: white;
  box-shadow: 0px 6px 0px 0px rgb(231, 227, 235);
  border: 1px solid rgb(231, 227, 235);
  border-bottom: none;
  border-radius: 24px;
  padding: 24px;
}
.topFarmBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.absDiv {
  position: relative;
  width: fit-content;
}
.absDiv img {
  width: 32px;
  height: 32px;
}
.absDiv .absImg {
  position: absolute;
  top: 20%;
  left: 40%;
  width: 52px;
  height: 52px;
}
.farmName {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  gap: 7px;
}
.farmName h1 {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  color: var(--dark-purple);
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.check {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  font-weight: 400;
  height: 24px;
  padding: 0px 4px;
  font-size: 12px;
  color: var(--light-purple);
  background: rgb(250, 249, 250);
  border: 2px solid var(--light-purple);
  justify-self: flex-start;
}
.redCheck {
  justify-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  font-weight: 400;
  height: 24px;
  padding: 0px 4px;
  font-size: 12px;
  background-color: rgb(250, 249, 250);
  color: rgb(237, 75, 158);
  background: rgb(250, 249, 250);
  border: 2px solid rgb(237, 75, 158);
}
.multiplier {
  display: inline-flex;
  align-items: center;
  background-color: var(--light-purple);
  color: white;
  border-radius: 16px;
  font-weight: 400;
  height: 28px;
  padding: 0px 8px;
  font-size: 14px;
}
.detailsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 0px;
  margin-top: 40px;
  gap: 15px;
}
.detailRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.detailRow p,
.detailRow h5 {
  color: var(--dark-purple);
  font-weight: 400;
  line-height: 1;
  font-size: 16px;
}
.detailRow h5 {
  font-weight: 600 !important;
}
.earnedDiv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}
.earnedDiv h5,
.connectDiv p {
  color: var(--purple-color);
  font-weight: 600;
  line-height: 1;
  font-size: 15px;
}
.earnedDiv .earnRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.earnRow p {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  color: rgb(189, 194, 196);
}
.earnedDiv button {
  width: 100%;
}
.harvest {
  position: relative;
  align-items: center;
  border: 1px solid rgb(233, 234, 235);
  border-radius: 16px;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.03em;
  line-height: 1;
  opacity: 1;
  outline: 0px;
  transition: all 0.2s ease 0s;
  height: 48px;
  padding: 0px 24px;
  background-color: rgb(233, 234, 235);
  box-shadow: none;
  color: rgb(189, 194, 196);
  cursor: not-allowed;
  width: fit-content !important;
}
.connectDiv {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  margin-top: 13px;
}
.connectDiv button {
  width: 100%;
}
.hiddenSwitch {
  text-align: center;
  width: 100%;
  color: var(--button-bg);
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  margin-top: 16px;
  padding-top: 24px;
  border-top: 2px solid rgb(231, 227, 235);
  cursor: pointer;
  user-select: none;
}
.hidden {
  display: none;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 20px;
}
.hidden a {
  color: var(--button-bg);
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
}
.hidden a:hover {
  text-decoration: underline;
}
.openHidden {
  display: flex;
}
.rotate {
  transform: rotate(180deg);
}
@media screen and (max-width: 812px) {
  .farmBox {
    max-width: 420px;
    width: 100%;
  }
}
