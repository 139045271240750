/* General Header Styling */
.header {
    width: 100%;
    display: flex;
    justify-self: center;
    position: relative;
}

.logo {
    width: 140px;
    height: 40px;
}

.header__links {
    justify-items: center;
    align-items: center;
    display: flex;
    width: 100%;
    gap: 15px;
    padding-left: 15px;
    overflow: auto;
}

.link {
    display: flex;
    text-align: center;
}

.header__links a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}

.headerItem {
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 5px;
    font-weight: 500;
    transition: 0.3s;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.headerItem:hover {
    /* background-color: rgb(34, 42, 58); */
    cursor: pointer;
    color: #E22901;
}

.headerItem:active {
    color: #E2290126;
}

.header__wallet button:hover {
    background-color: #0056b3;
}

/* Mobile Menu Icon */
.header__menu-icon {
    margin-left: 20px;
    display: none;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
}

.header__menu-icon .menu-bar {
    width: 25px;
    height: 3px;
    background-color: #E2290196;
}

/* Mobile Menu */
.header__mobile-menu {
    position: absolute;
    top: 100%;
    left: 30px;
    width: calc(100% - 60px);
    border-radius: 10px;
    background-color: #181717;
    animation: slideDown 0.3s ease-in-out;
    z-index: 1000;
    justify-items: center;
}

.header__mobile-menu a {
    text-decoration: none;
    color: #ada3a3;
    display: block;
    padding: 10px;
    font-weight: bold;
}

/* Animations */
@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media (max-width: 1199px) {
    header {
        padding-left: 30px;
        padding-right: 30px;
    }

    .header__links {
        gap: 0;
    }
}

@media (max-width: 991px) {
    .header__links {
        display: none;
        /* Hide group of links */
    }
    
    .header__menu-icon {

        display: flex;
        /* Show menu icon */
    }
}

@media (max-width: 767px) {
    .header{
        width: 100%;
        padding: 0 15px;
    }

    .header__menu-icon {
        margin-left: 12px;
    }

    .link {
        width: 100%;
        justify-items: center;
        display: flex;
        text-align: center;
    }
    .link:hover {
        background-color: #353232;
    }

    .header__menu-col {
        padding: 0;
    }

    .connectButtonWrap {
        display: none;
    }

    .header__mobile-menu {
        left: 15px;
        width: calc(100% - 30px);
    }
}